/* You can add global styles to this file, and also import other style files */
:root {
  --primary-color-1: #EEEEEE;
  --primary-color-2: #E0E0E0;
  --primary-color-3: #FFFFFF;

  --primary-color-footer: #212121;

  --secundary-color-1: #FF6D40;

  --success-color-1: #2F5711;
  --success-color-2: #C8DBB9;


  --error-color-1: #A8200D;
  --error-color-2: #EAC4BF;

  /* background */
  --primary-background-color: #FFFFFF;
  --secondary-background-color: #EEEEEE;
  --third-background-color: #992400;
  --fourth-background-color: #211858;
  --fifth-background-color: #E0E0E0;

  /* button */
  --primary-button-color: #992400;
  --secondary-button-color: #FF6D40;
  --third-button-color: #FFFFFF;

  /* text */
  --app-font-family: 'Helvetica', 'Roboto';
  --primary-text-color: #000000;
  --secondary-text-color: #211858;
  --third-text-color: #424242;

}

* {
  font-family: var(--app-font-family);
}

html,
body {
  height: 100%;
  background-color: var(--primary-color-3);
  color: var(--primary-text-color);
  margin: 0;
}

.primaryButton {
  background-color: var(--primary-button-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

.secundaryButton {
  background-color: var(--secondary-button-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

.thirdButton {
  background-color: var(--third-button-color) !important;
  border: 1px solid var(--primary-button-color) !important;
  color: var(--primary-button-color) !important;
}

.p-inputtext:hover .p-inputtext:enabled:focus .p-inputtext:enabled:hover:focus {
  border-color: var(--primary-color-1) !important;
  box-shadow: var(--primary-color-1) !important;
}

.p-inputtext:enabled:focus {
  border-color: var(--primary-color-1) !important;
  /*box-shadow: 0 0 0 0.2rem var(--primary-color-1) brightness(50%) !important;*/
}


.p-card .p-card-content {
  padding: 0 !important;
}

.p-card {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-fieldset * {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-dialog .p-dialog-header {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-dialog .p-dialog-content {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-inputtext {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-toast * {
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-toast .p-toast-message.p-toast-message-success {
  background-color: var(--success-color-2) !important;
  border: var(--success-color-1) solid !important;
  font-family: var(--app-font-family);
}

.p-toast .p-toast-message.p-toast-message-error {
  background-color: var(--error-color-2) !important;
  border: var(--error-color-1) solid !important;
  font-family: var(--app-font-family);
}

.yesButton {
  background-color: var(--success-color-2) !important;
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.noButton {
  background-color: var(--error-color-2) !important;
  color: var(--primary-text-color) !important;
  font-family: var(--app-font-family);
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-text-color) !important;
  border: 4px solid;
  background: var(--neutral-color-1) !important;
  box-shadow: none !important;

}




input[type='checkbox'] {
  filter: grayscale(1);
  /* can´t change default blue color, so we turn it grey */
}

.small {
  font-size: small;
  font-family: var(--app-font-family);
}

.p-knob-text {
  font-size: medium !important;
  font-family: var(--app-font-family);
}

/* snack bar styles */
.snackbar-info {
  background-color: var(--primary-color-1) !important;
  font-family: var(--app-font-family);
  color: var(--primary-text-color) !important;

  button {
    background-color: var(--primary-color-1) !important;
    color: var(--primary-text-color) !important;
    font-family: var(--app-font-family);
  }
}

.snackbar-warn {
  background-color: var(--secundary-color-1) !important;
  color: var(--primary-color-1) !important;
  font-family: var(--app-font-family);

  button {
    background-color: var(--secundary-color-1) !important;
    color: var(--primary-color-1) !important;
    font-family: var(--app-font-family);
  }
}

.snackbar-error {
  background-color: var(--error-color-1) !important;
  font-family: var(--app-font-family);
  color: white !important;

  button {
    background-color: var(--error-color-1) !important;
    color: white !important;
    font-family: var(--app-font-family);
  }
}

.snackbar-success {
  background-color: var(--success-color-1) !important;
  font-family: var(--app-font-family);
  color: white !important;

  button {
    background-color: var(--success-color-1) !important;
    color: white !important;
    font-family: var(--app-font-family);
  }
}

/*  */